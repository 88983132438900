import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../components/modal';
import Button from '../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import Navbar from '../components/navbars/courseLandingNavbar';
import ReactGA from 'react-ga'
import Curriculum from '../components/prep-curriculum';
import PrepCourseForm from '../components/forms/prepCourseForm';
import courseData from '../utils/course-data';
import moment from 'moment';

const CourseContainer = styled.div`
  display: block;
  height: 475px;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 175px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
    .cta-syllabus {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`

const CurriculumSection = styled(Container)`
  max-width: 1000px;
  padding-bottom: 0px;

  p {
    padding-top: 20px;
    text-align: left;
  }
  h2 {
    margin-top: 40px;
    text-align: left;
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }

  ul {
    font-family: 'roboto-mono';
    padding-left: 40px;
    list-style: none;
  }

  hr {
    margin-top: 60px;
  }
`

const FeaturesSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
.btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const VideoRow = styled(Row)`
  margin: 0 auto;
  margin-top: 40px;
  max-width: 800px;
`
const DatesRow = styled(Row)`
  margin-top: 40px;
  .dates-container {
    background-color: var(--yellow);
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
    h2 {
      text-align: center;
      padding-top: 20px;
    }
  }
  .dates-table-container {
    padding: 20px 50px 0px 50px;
  }
  .dates-table-row {
    margin-bottom: 25px;
    margin-top: 35px;
  }
  .dates-start {
    h5 {
      margin-bottom: 5px;
    }
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
  }
  .dates-price {
    p {
      text-align: center;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 16px;
      color: var(--black);

      a {
        color: var(--blue);
      }

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-pace {
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-cta-container {
    padding-top: 20px;
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 25px;
    }
  }
  .dates-cta {
    background: #fff;
    border: 2px solid #000;
    padding: 10px 10px;
    display: inline-block;
    text-align: center;
    color: #000;
    -webkit-text-decoration: none;
    text-decoration: none;
    box-shadow: #000 -10px 8px 0px;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 14px;
    min-width: 150px;
    text-align: center;
    font-family: 'roboto-mono';
    margin-top: -10px;
    margin-bottom: 20px;
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
`;

const CurriculumSchedule = styled.div`
  margin-top: 60px;
`

const ModalContainer = styled.div`
  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  .cta-container a {
    width: 100%;
    display: block;

    &:hover {
      background-color: var(--yellow);
    }
  }
`

const Index = ({location}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'waitlist',
    })
  };

  return (
    <Layout>
      <CourseContainer>
        <Navbar location={location}/>
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <PrepCourseForm close={handleClose} align='center' price={`${process.env.GATSBY_PREP_PRICE}`} location={location} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className='col-hero-text' md={12}>
              <h1>Prep Course | dev30</h1>
              <p className='subtext'>dev30 is a 30-day cohort-based JavaScript course that prepares beginners for Parsity.</p>
              <a href='https://dev30.xyz' target='_blank'>
                <Button  fireGTMEvent={fireGTMEvent} text={'Enroll'} />
              </a>
            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer>
      <CurriculumSection>
        <Container>
          <Row id='curriculum'>
            <Col md={12}>
              <h2>What is It?</h2>
              <p>This isn't your ordinary coding course - this is a community-focused program built for builders and creators. We'll focus on developing your coding life in 4 areas:</p>
              <ul>
                <li>🔨  <strong>Skills</strong>: You'll learn the complete fundamentals of JavaScript using our self-paced curriculum and live Zoom office hours.</li>
                <li>🧠  <srtrong>Mindset</srtrong>: You'll listen to a short daily podcast 🎙️ (~5mins) to feed your new coding brain for each of the 30 days.</li>
                <li>🛏️  <strong>Habits</strong>: You'll build life-changing routines balancing work and rest.</li>
                <li>🌎  <strong>Network</strong>: You'll "learn in public" and kick-off building your personal brand by posting on LinkedIn each day for 30 days alongside a cohort of other learners.</li>
              </ul>

              <CurriculumSchedule>
                <Curriculum/>
              </CurriculumSchedule>
              <div className="text-center btn-apply-curriculum">
                <a href='https://dev30.xyz' target='_blank'>
                  <Button  fireGTMEvent={fireGTMEvent} text={'Register'} />
                </a>
              </div>
              <hr></hr>
            </Col>
          </Row>
        </Container>
      </CurriculumSection>
      <FeaturesSection>
        <Container>         
          <DatesRow>
            <Col md={12}>
              <div className='dates-container'>
                <h2>Dates & Deadlines</h2>
                <div className='dates-table-container'>
                  {courseData.upcomingPrepCourses.map(function (course, i) {
                    return (
                      <>
                        <Row className='dates-table-row'>
                          <Col md={3}>
                            <div className='dates-start'>
                              <h5>{moment(course.startDate).format('MMMM D, YYYY')}</h5>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className='dates-price'>
                              <p>Fee: $49 for early payment ($149 after that) - goes toward tuition if accepted to Parsity.</p>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className='dates-pace'>
                              <p>Deadline to Register: {moment(course.startDate).subtract(8, "days").format('MMMM D, YYYY')}.</p>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className='dates-cta-container'>
                              <div>
                                <a href='https://dev30.xyz' target='_blank'>
                                  <Button  classToAdd='dates-cta' fireGTMEvent={fireGTMEvent} text={'Register'} />
                                </a>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        { (i === 0 && courseData.upcomingPrepCourses.length > 1) ? <hr></hr> : null }
                      </>
                    )
                  })}
                </div>
              </div>
            </Col>
          </DatesRow>
        </Container>
      </FeaturesSection>
    </Layout>
  )
}

export default Index
